import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import {
  SuspenseWithPerf,
  useAuth,
  useFirestore,
  useFirestoreCollectionData
} from "reactfire";
import Text from "../../components/Text";
import { Table, Button } from "react-bootstrap";
import avatar from "../../images/icons/avatar.svg";
import check from "../../images/icons/check.svg";
import cross from "../../images/icons/cross.svg";
import moment from "moment";
import ticketHelpers from "../../helpers/ticketHelpers";
import LoadingSpinner from "../../components/LoadingSpinner";

function Reservations() {
  const auth = useAuth();
  const user = auth.currentUser;
  // lazy load the Firestore SDK and create a document reference
  const pcRef = useFirestore()
    .collection("events")
    .doc("premium-cup");

  const reservationsRef = useFirestore()
    .collection("events")
    .doc("premium-cup")
    .collection("reservations");
  const reservations = useFirestoreCollectionData(reservationsRef);

  if (user == null || user.isAnonymous) {
    return (
      <div className="mx-5">
        Du bist hierfür nicht berechtigt. Klick oben aufs Logo :-)
      </div>
    );
  }

  const reservationsSorted = _.orderBy(reservations, ["familyName"], ["asc"]); // Use Lodash to sort array by 'name'

  const now = moment();

  const reservationsEnhanced = reservationsSorted.map(res => {
    const endDate = ticketHelpers.getTicketEndDate(res.time);
    const isValid = now.diff(endDate) < 0;
    return {
      ...res,
      validUntil: endDate,
      isValid
    };
  });

  return (
    <SuspenseWithPerf fallback={<LoadingSpinner/>} traceId={"reservations"}>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="d-flex justify-content-between">
              <Link to="/admin" className="btn btn-primary">
                ‹ Zurück
              </Link>
              <Button
                onClick={async () => {
                  let slotsReservationTaken = 0;
                  reservationsEnhanced.forEach(res => {
                    // count if its still active
                    console.log(res);
                    if (res.isValid) {
                      slotsReservationTaken += res.persons;
                    }
                  });
                  await pcRef
                    .update({slotsReservationTaken})
                    .then(() =>
                      alert(
                        "Abgelaufene Reservierungen sind wieder freigegeben."
                      )
                    )
                    .catch(error => {
                      alert(error.message || error);
                    });
                }}
                variant="success"
              >
                Plätze freigeben
              </Button>
            </div>
            <Text use="callout" color="textColorLight" className="mt-3 mb-3">
              Die Gültigkeit aktualisiert sich nicht automatisch. Hierzu einfach
              die Seite erneut öffnen.
            </Text>
            <Table responsive variant="dark" striped bordered hover>
              <thead>
              <tr>
                <th>Name</th>
                <th className="text-center">
                  <img
                    src={avatar}
                    style={{width: 16, height: 16, objectFill: "contain"}}
                    alt="Personen"
                  />
                </th>
                <th>Gültig</th>
              </tr>
              </thead>
              <tbody>
              {reservationsEnhanced.map((res, index) => {
                const endDateMoment = moment(res.validUntil);
                return (
                  <tr key={index}>
                    <td>
                      {res.familyName}, {res.firstName}
                      <br/>
                      <Text use="footnote" color="textColorLight">
                        {res.phoneNumber}
                      </Text>
                    </td>
                    <td className="text-center">{res.persons}</td>
                    <td className="text-right">
                      <div className="d-flex align-items-center justify-content-end">
                        <div className="mr-2">
                          {endDateMoment.format("HH:mm")}
                          <Text use="footnote" color="textColorLight">
                            {endDateMoment.format("DD.MM")}
                          </Text>
                        </div>
                        <img
                          src={res.isValid ? check : cross}
                          style={{width: 24, height: 24}}
                          alt="icon"
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </SuspenseWithPerf>
  );
}

export default Reservations;
