import React from "react";
import { useFirestoreDocData, useFirestore, SuspenseWithPerf } from "reactfire";
import Text from "../../components/Text";
import liveIcon from "../../images/icons/live.svg";
import CardContent from "../../components/CardContent";
import Card from "../../components/Card";
import Colors from "../../helpers/Colors";

const STATE_MAP = {
  GREEN: {
    text:
      "Es sind noch ausreichend viele Plätze vorhanden. Wir freuen uns auf euch!",
    color: "green"
  },
  ORANGE: {
    text: "Es sind momentan nicht mehr viele Plätze verfügbar, besser du beeilst dich.",
    color: "orange"
  },
  RED: {
    text:
      "Es sind momentan leider kaum noch Plätze verfügbar. Gegebenfalls musst du mit etwas Wartezeit rechnen.",
    color: "red"
  },
  OFF: {
    text:
      "Es sind momentan leider keine Plätze mehr verfügbar. Es ist vor Ort mit längeren Wartezeiten zu rechnen.",
    color: "red"
  }
};

function getAutoCapacityDisplayMode(slotsVolume, slotsTaken) {
  const slotsAvailable = slotsVolume - slotsTaken;
  const ratio = slotsAvailable / slotsVolume;
  return ratio >= 0.2666 ? "GREEN" : ratio >= 0.0666 ? "ORANGE" : "RED";
}

function AvailableSlots() {
  // lazy load the Firestore SDK and create a document reference
  const pcRef = useFirestore()
    .collection("events")
    .doc("premium-cup");

  // subscribe to the doc. just one line!
  const pc = useFirestoreDocData(pcRef);

  // get the value from the doc
  const status =
    pc.capacityDisplayMode != null && pc.capacityDisplayMode === "AUTO"
      ? getAutoCapacityDisplayMode(pc.slotsVolume, pc.slotsTaken)
      : pc.capacityDisplayMode;

  const info = STATE_MAP[status];

  return (
    <SuspenseWithPerf fallback={"Einen Moment"} traceId={"available-slots"}>
      <Card>
        <CardContent>
          <div className="mb-1 d-flex align-items-center">
            <Text use="headline" color="textColorLight">
              Live status
            </Text>
          </div>
          <div className="text-center mb-1 mt-3">
            <div
              style={{
                backgroundColor: Colors[info.color],
                width: 60,
                height: 60,
                borderRadius: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0 auto"
              }}
              className="pulse mb-4"
            >
              <img
                src={liveIcon}
                aria-hidden="true"
                alt="Live Status"
                style={{
                  width: 36,
                  height: 36
                }}
              />
            </div>
            <Text use="headline">{info.text}</Text>
          </div>
        </CardContent>
      </Card>
    </SuspenseWithPerf>
  );
}

export default AvailableSlots;
