import React, { useState } from "react";
import {
  AuthCheck,
  useAuth,
  useFirestore,
  useFirestoreDocData
} from "reactfire";
import AvailableSlots from "../home/AvailableSlots";
import {
  DropdownButton,
  Dropdown,
  Modal,
  Col,
  Button,
  Form,
  Row
} from "react-bootstrap";
import CardContent from "../../components/CardContent";
import Card from "../../components/Card";
import Text from "../../components/Text";
import userAdd from "../../images/icons/userAdd.svg";
import userRemove from "../../images/icons/userRemove.svg";
import LoginForm from "../../components/LoginForm";
import { Formik } from "formik";
import * as yup from "yup";
import { Link } from "react-router-dom";

const STATE_MAP = {
  AUTO: {
    text: "Automatisch",
    variant: undefined
  },
  GREEN: {
    text: "Grün",
    variant: "success"
  },
  ORANGE: {
    text: "Orange",
    variant: "warning"
  },
  RED: {
    text: "Rot",
    variant: "danger"
  },
  OFF: {
    text: "Schotten dicht",
    variant: "danger"
  }
};

function refireAnimation(id) {
  const el = document.getElementById(id);
  // does not work in IOS (reflow)
  // el.classList.remove("single-pulse");
  // void el.clientHeight; // trigger a DOM reflow
  // el.classList.add("single-pulse");
  el.classList.remove("single-pulse");
  setTimeout(function () {
    el.classList.add("single-pulse");
  }, 80);
}

function Admin() {
  const auth = useAuth();
  const user = auth.currentUser;

  const [lgShow, setLgShow] = useState(false);
  // lazy load the Firestore SDK and create a document reference
  const pcRef = useFirestore()
    .collection("events")
    .doc("premium-cup");

  // subscribe to the doc. just one line!
  const pc = useFirestoreDocData(pcRef);

  const setStatus = value => {
    pcRef.update({capacityDisplayMode: value}).catch(error => {
      alert(error.message || error);
    });
  };

  const isLive = pc.livestream;

  const status = STATE_MAP[pc.capacityDisplayMode];

  if (user != null && user.isAnonymous) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-sm-12 mb-3 text-center">
            Du hast keine Berechtigung mit dem aktuellen Benutzer.
            <div className="mt-3 mb-3">
              <LoginForm />
            </div>
            <Link to="/">Zurück</Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <AuthCheck fallback={<LoginForm />}>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-6 mb-3">
            <Card>
              <CardContent>
                <div className="d-flex flex-row justify-content-between align-items-stretch mb-3">
                  <div
                    id="visitorAdd"
                    onClick={() => {
                      refireAnimation("visitorAdd");
                      pcRef
                        .update({slotsTaken: (pc.slotsTaken += 1)})
                        .catch(error => {
                          alert(error.message || error);
                        });
                    }}
                    className="disable-zoom mr-1 text-center"
                    style={{
                      backgroundColor: "var(--green)",
                      paddingTop: 15,
                      paddingBottom: 15,
                      paddingLeft: 5,
                      paddingRight: 5,
                      borderRadius: 8,
                      cursor: "pointer",
                      width: "100%"
                    }}
                  >
                    <img
                      style={{
                        width: "80px",
                        height: "80px",
                        marginBottom: 10,
                        objectFill: "contain"
                      }}
                      alt="Bild"
                      src={userAdd}
                    />
                    <Text use="subhead">Besucher kommt</Text>
                  </div>
                  <div
                    id="visitorRemove"
                    onClick={() => {
                      refireAnimation("visitorRemove");
                      pcRef
                        .update({slotsTaken: (pc.slotsTaken -= 1)})
                        .catch(error => {
                          alert(error.message || error);
                        });
                    }}
                    className="disable-zoom ml-1 text-center"
                    style={{
                      backgroundColor: "var(--orange)",
                      paddingTop: 15,
                      paddingBottom: 15,
                      paddingLeft: 5,
                      paddingRight: 5,
                      borderRadius: 8,
                      cursor: "pointer",
                      width: "100%"
                    }}
                  >
                    <img
                      style={{
                        width: "80px",
                        height: "80px",
                        marginBottom: 10,
                        objectFill: "contain"
                      }}
                      alt="Bild"
                      src={userRemove}
                    />
                    <Text use="subhead">Besucher geht</Text>
                  </div>
                </div>
                <div
                  id="visitorReservationAdd"
                  onClick={() => {
                    refireAnimation("visitorReservationAdd");

                    pcRef
                      .update({
                        slotsTaken: (pc.slotsTaken += 1),
                        slotsReservationTaken: (pc.slotsReservationTaken -= 1)
                      })
                      .catch(error => {
                        alert(error.message || error);
                      });
                  }}
                  className="disable-zoom mb-3 d-flex align-items-center justify-content-center"
                  style={{
                    backgroundColor: "var(--colors--purple)",
                    paddingTop: 15,
                    paddingBottom: 15,
                    paddingLeft: 5,
                    paddingRight: 5,
                    borderRadius: 8,
                    cursor: "pointer",
                    width: "100%"
                  }}
                >
                  <img
                    style={{
                      width: "30px",
                      height: "30px",
                      marginRight: 10,
                      objectFill: "contain"
                    }}
                    alt="Bild"
                    src={userAdd}
                  />
                  <Text use="subhead">Reservierung kommt</Text>
                </div>
                <div className="text-center mb-3">
                  <Text use="title1">
                    Noch freie Plätze:{" "}
                    {pc.slotsVolume - pc.slotsTaken - pc.slotsReservationTaken}
                  </Text>
                </div>
                <div className="d-flex flex-row">
                  <div style={{ width: "100%" }} className="mr-1">
                    <Text use="footnote">Kapazität: {pc.slotsVolume}</Text>
                    <Text use="footnote">Auf dem Gelände: {pc.slotsTaken}</Text>
                    <Button
                      onClick={() => setLgShow(true)}
                      size="sm"
                      className="mt-2"
                    >
                      Kontingent ändern
                    </Button>
                  </div>
                  <div style={{ width: "100%" }} className="ml-1">
                    <Text use="footnote">
                      Reservierbar: {pc.slotsReservationVolume}
                    </Text>
                    <Text use="footnote">
                      Reserviert: {pc.slotsReservationTaken}
                    </Text>
                    <Link
                      className="btn btn-primary btn-sm mt-2"
                      to="/reservations"
                    >
                      Reservierungen
                    </Link>
                  </div>
                </div>
                <Modal
                  size="lg"
                  show={lgShow}
                  onHide={() => setLgShow(false)}
                  aria-labelledby="example-modal-sizes-title-lg"
                >
                  <Modal.Header closeButton>
                    <Text color="textColorLight">Kontingent bearbeiten</Text>
                  </Modal.Header>
                  <Modal.Body>
                    <Formik
                      validationSchema={yup.object({
                        slotsVolume: yup.number().required(),
                        slotsTaken: yup.number().required(),
                        slotsReservationVolume: yup.number().required(),
                        slotsReservationTaken: yup.number().required()
                      })}
                      onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true);
                        pcRef.update({ ...values }).catch(error => {
                          console.error(error);
                          alert(error.message || error);
                        });
                        setLgShow(false);
                        setSubmitting(false);
                      }}
                      initialValues={{
                        slotsVolume: pc.slotsVolume,
                        slotsTaken: pc.slotsTaken,
                        slotsReservationVolume: pc.slotsReservationVolume,
                        slotsReservationTaken: pc.slotsReservationTaken
                      }}
                    >
                      {({
                        handleSubmit,
                        handleChange,
                        handleReset,
                        isSubmitting,
                        values,
                        errors
                      }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                          <Form.Group controlId="slotsVolume">
                            <Form.Label>Maximale Besucheranzahl:</Form.Label>
                            <Form.Control
                              type="number"
                              name="slotsVolume"
                              value={values.slotsVolume}
                              onChange={handleChange}
                              isInvalid={!!errors.slotsVolume}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.slotsVolume}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group controlId="slotsTaken">
                            <Form.Label>Momentane Besucheranzahl:</Form.Label>
                            <Form.Control
                              type="number"
                              name="slotsTaken"
                              value={values.slotsTaken}
                              onChange={handleChange}
                              isInvalid={!!errors.slotsTaken}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.slotsTaken}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group controlId="slotsReservationVolume">
                            <Form.Label>Maximale Reservierungen:</Form.Label>
                            <Form.Control
                              type="number"
                              name="slotsReservationVolume"
                              value={values.slotsReservationVolume}
                              onChange={handleChange}
                              isInvalid={!!errors.slotsReservationVolume}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.slotsReservationVolume}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group controlId="slotsReservationTaken">
                            <Form.Label>Momentane Reservierungen:</Form.Label>
                            <Form.Control
                              type="number"
                              name="slotsReservationTaken"
                              value={values.slotsReservationTaken}
                              onChange={handleChange}
                              isInvalid={!!errors.slotsReservationTaken}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.slotsReservationTaken}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Row>
                            <Col>
                              <Button
                                type="submit"
                                size="lg"
                                disabled={isSubmitting}
                              >
                                Speichern
                              </Button>
                            </Col>
                            <Col className="text-right">
                              <Button
                                variant="secondary"
                                size="lg"
                                onClick={() => {
                                  handleReset();
                                  setLgShow(false);
                                }}
                              >
                                Abbrechen
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  </Modal.Body>
                </Modal>
              </CardContent>
            </Card>
          </div>
          <div className="col-sm-12 col-md-6 mb-4">
            <Card>
              <CardContent>
                <div className="mb-2">
                  <Text use="headline">Besucherstatus:</Text>
                </div>
                <DropdownButton
                  id="dropdown-item-button"
                  title={status.text}
                  variant={status.variant}
                >
                  {Object.keys(STATE_MAP).map(key => (
                    <Dropdown.Item
                      key={key}
                      as="button"
                      onClick={() => {
                        setStatus(key);
                      }}
                    >
                      {STATE_MAP[key].text}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </CardContent>
            </Card>
            <div className="mt-3">
              <AvailableSlots withButton={false}/>
            </div>
            <div className="mt-3 text-center">
              <Button
                onClick={async () => {
                  await pcRef
                    .update({livestream: !pc.livestream})
                    .catch(error => {
                      console.error(error);
                      alert(error.message || error);
                    });
                }}
                variant={isLive ? "danger" : "primary"}
              >
                Stream {!isLive ? "live schalten" : "abschalten"}
              </Button>
            </div>
            <div className="mt-3">
              <LoginForm/>
            </div>
            {user != null && (
              <div className="mt-3 mb-5">
                <Text use="caption1" color="textColorLight">
                  UserId: {user.uid}
                </Text>
                <Text use="caption1" color="textColorLight">
                  User: {user.email}
                </Text>
                <Text use="caption1" color="textColorLight">
                  Anonymous: {user.isAnonymous.toString()}
                </Text>
              </div>
            )}
          </div>
        </div>
      </div>
    </AuthCheck>
  );
}

export default Admin;
