const TextStyles = {
  hero: {
    fontSize: "48px",
    lineHeight: "57px",
    fontWeight: "bold"
  },
  largeTitle: {
    fontSize: "var(--large--title)",
    lineHeight: "41px",
    fontWeight: "bold"
  },
  title1: {
    fontSize: "var(--title-1)",
    lineHeight: "34px",
    fontWeight: "normal"
  },
  title2: {
    fontSize: "var(--title-2)",
    lineHeight: "28px",
    fontWeight: "normal"
  },
  title3: {
    fontSize: "var(--title-3)",
    lineHeight: "25px",
    fontWeight: "normal"
  },
  headline: {
    fontSize: "var(--headline)",
    lineHeight: "22px",
    fontWeight: "600"
  },
  body: {
    fontSize: "var(--body)",
    lineHeight: "22px",
    fontWeight: "normal"
  },
  callout: {
    fontSize: "var(--callout)",
    lineHeight: "21px",
    fontWeight: "normal"
  },
  subhead: {
    fontSize: "var(--subhead)",
    lineHeight: "20px",
    fontWeight: "normal"
  },
  footnote: {
    fontSize: "var(--footnote)",
    lineHeight: "18px",
    fontWeight: "normal"
  },
  caption1: {
    fontSize: "var(--caption1)",
    lineHeight: "16px",
    fontWeight: "normal"
  },
  caption2: {
    fontSize: "var(--caption2)",
    lineHeight: "13px",
    fontWeight: "normal"
  }
};

export default TextStyles;