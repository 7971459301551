import Ticket from "./Ticket";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import React from "react";
import Checkout from "./Checkout";
import Intro from "./Intro";
import Tos from "./Tos";

function Reservation() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/ticket`}>
        <Ticket />
      </Route>
      <Route path={`${path}/tos`}>
        <Tos />
      </Route>
      <Route path={`${path}/checkout`}>
        <Checkout />
      </Route>
      <Route path={`${path}/*`}>
        <Intro />
      </Route>
    </Switch>
  );
}

export default Reservation;
