const Colors = {
  backgroundLight: "var(--background-light)",
  linkColor: "var(--link-color)",
  linkColorHover: "var(--link-color-hover)",
  textColor: "var(--text-color)",
  textColorLight: "var(--text-color-light)",
  backgroundDark: "var(--background-dark)",

  red: "var(--colors--red)",
  orange: "var(--colors--orange)",
  yellow: "var(--colors--yellow)",
  green: "var(--colors--green)",
  lightblue: "var(--colors--lightblue)",
  blue: "var(--colors--blue)",
  purple: "var(--colors--purple)",
  berry: "var(--colors--berry)",
  lavender: "var(--colors--lavender)",
  sand: "var(--colors--sand)",
  gray: "var(--colors--gray)",
  rose: "var(--colors--rose)"
};

export default Colors;
