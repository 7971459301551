import React from "react";
import Colors from "../helpers/Colors";
import TextStyles from "../helpers/TextStyles";

function Text({ children, use, color, className, bold, style }) {
  const textStyle = TextStyles[use || "body"];
  const useColor = Colors[color || "textColor"];
  if (textStyle != null) {
    const { fontSize, lineHeight, fontWeight } = textStyle;
    return (
      <p
        className={className}
        style={{
          fontSize,
          lineHeight,
          fontWeight: bold ? "bold" : fontWeight,
          color: useColor,
          marginBottom: 0,
          ...style
        }}
      >
        {children}
      </p>
    );
  }
  return (
    <p
      style={{ color: useColor, marginBottom: 0, ...style }}
      className={className}
    >
      {children}
    </p>
  );
}

export default Text;
