import React from "react";
import styles from "./InputField.module.css";

function InputField({
                      onChange,
                      onBlur,
                      value,
                      autoComplete,
                      name,
                      type,
                      required,
                      placeholder,
                      label,
                      hidden,
                      error
                    }) {
  return (
    <div className={hidden ? styles.wrapperHidden : styles.wrapper}>
      {label && (
        <label htmlFor={name} className={styles.label}>
          {label}
        </label>
      )}
      <input
        id={name}
        type={type}
        name={name}
        autoComplete={autoComplete}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        placeholder={placeholder}
        required={required}
        hidden={hidden}
        className={styles.field}
      />
      {error && error}
    </div>
  );
}

export default InputField;
