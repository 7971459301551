import React from "react";
import Text from "../../components/Text";
import { Link } from "react-router-dom";
import CardContent from "../../components/CardContent";
import Card from "../../components/Card";

function Intro() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12 text-center">
          <Text use="hero" className="mb-4">
            So einfach geht's:
          </Text>
          <div className="mb-3">
            <Card>
              <CardContent>
                <ol className="text-left featurelist">
                  <li>
                    <Text>
                      Reserviere bis zu 4 Plätze für dich und deine Liebsten –
                      völlig kostenfrei!{" "}
                      <span role="img" aria-label="emoji" aria-hidden={"true"}>
                        🎫
                      </span>
                    </Text>
                  </li>
                  <li>
                    <Text>
                      Löse die Reservierung innerhalb von{" "}
                      <strong>30 Minuten</strong> vor Ort ein{" "}
                      <span role="img" aria-label="emoji" aria-hidden={"true"}>
                        🕑
                      </span>
                    </Text>
                  </li>
                  <li>
                    <Text>
                      Genieße Beachvolleyball der Extraklasse{" "}
                      <span role="img" aria-label="emoji" aria-hidden={"true"}>
                        🏐
                      </span>
                    </Text>
                  </li>
                </ol>
                <Link
                  className="mt-2 mb-4 btn btn-warning btn-lg"
                  to="/reservation/checkout"
                >
                  Los geht's
                </Link>
                <Text use="footnote" color="textColorLight">
                  Auch ohne der Reservierung lassen wir dich natürlich immer
                  aufs Gelände, solange wir das Besucherlimit dabei nicht
                  überschreiten{" "}
                  <span role="img" aria-label="emoji" aria-hidden={"true"}>
                    ✌️
                  </span>.
                </Text>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Intro;
