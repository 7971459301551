import { useAnalytics } from "reactfire";
import { useEffect } from "react";
import { withRouter } from "react-router-dom";

function PageTracker({ location }) {
  const analytics = useAnalytics();

  // By passing `location.pathname` to the second argument of `useEffect`,
  // we only log on first render and when the `pathname` changes
  useEffect(
    () => {
      analytics.logEvent("page-view", { path_name: location.pathname });
    },
    [analytics, location.pathname]
  );

  return null;
}

export default withRouter(PageTracker);
