import React from "react";
import styles from "./LoadingSpinner.module.css";

function LoadingSpinner() {
  return (
    <div className={styles.spinner}>
      <div className={styles.doubleBounce1}/>
      <div className={styles.doubleBounce2}/>
    </div>
  );
}

export default LoadingSpinner;
