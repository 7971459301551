import React from "react";
import Text from "../../components/Text";
import { Link } from "react-router-dom";

function Tos() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <Link to="/reservation/checkout" className="btn btn-warning mb-3">
            ‹ Zurück
          </Link>
          <Text use="title1" className="mb-3">
            Reservierungs-<br />bedingungen
          </Text>
          <Text use="body1" className="mb-3">
            Aufgrund der Corona-Pandemie ist nur eine begrenzte Anzahl an
            Zuschauern erlaubt, die wir zu deinem Schutz in keinem Fall
            überschreiten.
          </Text>
          <Text use="body1" className="mb-3">
            Damit du nicht umsonst anreist, bieten wir eine kostenlose und
            unverbindliche Reservierung von Plätzen an. So hast du ab dem
            Zeitpunkt deiner Reservierung bis zu 30 Minuten Zeit das Gelände zu
            betreten und dir einen Platz gesichert.
          </Text>
          <Text use="body1" className="mb-3">
            Während wir nach bestem Gewissen arbeiten, können wir nicht
            ausschließen, dass aufgrund von technischen Fehlern oder
            organisatorischen Gegebenheiten wir dir trotz einer Reservierung
            keinen Zutritt zum Gelände gewähren können. Dementsprechend ist
            deine Reservierung ohne Gewähr und wir behalten uns vor, diese zu
            stornieren oder dir den Zutritt am Eingang zu verwähren. In diesem
            Fall hast du kein Anrecht auf eine Erstattung und nutzt die
            unverbindliche Reservierung auf eigene Gefahr.
          </Text>
          <Text use="body1" className="mb-3">
            Deine für die Reservierung gemachten persönlichen Daten werden in
            "Firebase", einem Tool von Google gespeichert (Google Firebase, 1600
            Amphitheatre Parkway, Mountain View, CA 94043, USA). Dies hilft uns
            dabei, dir einen möglichst reibungslosen Ablauf anbieten zu können.
          </Text>
          <Text use="body1" className="mb-3">
            Deine Daten werden ausschließlich für die Ticket Reservierung
            verwendet und innerhalb von zwei Wochen nach Abschluss des Turniers
            am 16. August 2020 vollständig gelöscht. Bis dahin sind deine
            Angaben von Vereinsmitgliedern der Sportgemeinschaft Coesfeld 06
            e.V. einsehbar.
          </Text>
          <Link to="/reservation/checkout" className="btn btn-warning mb-5">
            ‹ Zurück
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Tos;
