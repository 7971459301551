import React, { useState } from "react";
import moment from "moment";
import {
  AuthCheck,
  useAuth,
  useFirestore,
  useFirestoreDocData
} from "reactfire";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import Text from "../../components/Text";
import placemark from "../../images/icons/placemark.svg";
import avatar from "../../images/icons/avatar.svg";
import Countdown from "react-countdown";
import ticketHelpers from "../../helpers/ticketHelpers";

function Ticket() {
  const auth = useAuth();
  const user = auth.currentUser;
  const pcRef = useFirestore()
    .collection("events")
    .doc("premium-cup");
  const ticketRef = pcRef
    .collection("reservations")
    .doc(user != null ? user.uid : "empty");
  const pc = useFirestoreDocData(pcRef);
  const ticket = useFirestoreDocData(ticketRef);

  const validTicket = ticketHelpers.isTicketValid(ticket);

  const [ticketValid, setTicket] = useState(validTicket);

  const [showDeletionModal, setShowDeletionModal] = useState(false);

  const Fallback = () => (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          Dein Ticket wurde erfolgreich storniert.<br />
          <Link to="reservation/intro" className="btn btn-warning mt-2 mr-2">
            Erneut reservieren
          </Link>
          <Link to="/" className="btn btn-outline-warning mt-2">
            Zur Startseite
          </Link>
        </div>
      </div>
    </div>
  );

  if (!ticketHelpers.hasTicket(ticket)) {
    return <Fallback />;
  }

  const TICKET_WHOLE = 17;

  return (
    <AuthCheck fallback={<Fallback />}>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 mb-4">
            <div
              className="text-center"
              style={{ maxWidth: 280, margin: "0 auto" }}
            >
              {ticketValid ? (
                <Text use="title1" bold>
                  Du hast erfolgreich reserviert!
                </Text>
              ) : (
                <Text use="title1" bold>
                  Deine Reservierung ist abgelaufen{" "}
                  <span role="img" aria-label="emoji" aria-hidden={"true"}>
                    😱
                  </span>
                </Text>
              )}
              <div
                style={{
                  borderRadius: 24,
                  overflow: "hidden"
                }}
                className="my-3 mx-5"
              >
                <div
                  className="px-4 py-3"
                  style={{
                    background: ticketValid
                      ? "var(--colors--purple)"
                      : "var(--colors--red)"
                  }}
                >
                  {ticketValid ? (
                    <div className="my-3">
                      <Text use="hero">
                        <Countdown
                          date={ticketHelpers.getTicketEndDate(ticket.time)}
                          renderer={({ minutes, seconds }) => (
                            <span>
                              {minutes < 10 ? "0" : ""}
                              {minutes}:{seconds < 10 ? "0" : ""}
                              {seconds}
                            </span>
                          )}
                          onComplete={() => {
                            setTicket(false);
                          }}
                        />
                      </Text>
                      <Text use="headline">MINUTEN GÜLTIG</Text>
                    </div>
                  ) : (
                    <div className="my-3">
                      <Text use="headline">ABGELAUFEN UM</Text>
                      <Text use="hero">
                        {moment(
                          ticketHelpers.getTicketEndDate(ticket.time)
                        ).format("HH:mm")}
                      </Text>
                    </div>
                  )}

                  <div className="d-flex align-items-center justify-content-center my-3">
                    <img
                      alt="Avatar"
                      src={avatar}
                      aria-hidden={"true"}
                      style={{ height: "auto", width: 36 }}
                      className="mr-2"
                    />
                    <Text use="hero">{ticket.persons}</Text>
                  </div>
                  <Text>
                    {ticket.firstName} {ticket.familyName}
                  </Text>
                </div>
                <div
                  className="py-3"
                  style={{
                    backgroundColor: "var(--background-light)",
                    position: "relative"
                  }}
                >
                  <div
                    style={{
                      border: "2px dashed var(--background-dark)",
                      position: "absolute",
                      top: -1,
                      left: 0,
                      right: 0
                    }}
                  />
                  <div
                    style={{
                      width: TICKET_WHOLE,
                      height: TICKET_WHOLE,
                      borderRadius: TICKET_WHOLE,
                      backgroundColor: "var(--background-dark)",
                      position: "absolute",
                      top: -TICKET_WHOLE / 2,
                      left: -TICKET_WHOLE / 2
                    }}
                  />
                  <div
                    style={{
                      width: TICKET_WHOLE,
                      height: TICKET_WHOLE,
                      borderRadius: TICKET_WHOLE,
                      backgroundColor: "var(--background-dark)",
                      position: "absolute",
                      top: -TICKET_WHOLE / 2,
                      right: -TICKET_WHOLE / 2
                    }}
                  />
                  <Text bold use="callout">
                    NR {moment(ticket.time).format("DD")}
                    {moment(ticket.time).format("HHmm")}
                  </Text>
                </div>
              </div>
              {!ticketValid ? (
                <div className="mb-3">
                  <Text use="body" className="mb-3">
                    Sofern es irgendwie möglich ist, lassen wir dich natürlich
                    trotzdem auf das Gelände. Überprüfe den aktuellen
                    Besucherstatus auf der <Link to="/">Startseite</Link>. Auf
                    Wunsch kannst du auch erneut reservieren.
                  </Text>
                  <Link to="reservation/checkout" className="btn btn-warning">
                    Erneut reservieren
                  </Link>
                </div>
              ) : (
                <div className={"mb-3"}>
                  <Text use="body">
                    Zeige einfach dein Smartphone am Eingang vor oder nenn uns
                    deine Ticket-ID.
                  </Text>
                </div>
              )}
              <Text use="body" color="textColorLight" className="mb-4">
                <span role="img" aria-label="emoji" aria-hidden={"true"}>
                  👋
                </span>&nbsp; BIS GLEICH
              </Text>
              <div>
                <a
                  className="btn btn-lg btn-warning"
                  href="https://www.google.de/maps/dir//Sportzentrum+S%C3%BCd,+48653+Coesfeld/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="Anfahrt"
                    src={placemark}
                    className="mr-1"
                    aria-hidden="true"
                    style={{ width: 16, height: "auto" }}
                  />
                  Anfahrt
                </a>
              </div>
              {ticketValid && (
                <>
                  <Button
                    variant="outline-danger"
                    onClick={() => setShowDeletionModal(true)}
                    className="mt-4"
                  >
                    Reservierung stornieren
                  </Button>
                  <Modal
                    show={showDeletionModal}
                    onHide={() => {
                      setShowDeletionModal(false);
                    }}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>
                        Bist du sicher, dass du deine Reservierung stornieren
                        möchtest?
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      Dies lässt sich nicht rückgängig machen. Du kannst im
                      Anschluss erneut reservieren, sofern ausreichend viele
                      Plätze zur Verfügung stehen.
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="danger"
                        onClick={async () => {
                          await pcRef.update({
                            slotsReservationTaken: (pc.slotsReservationTaken -=
                              ticket.persons)
                          });
                          await ticketRef.delete().catch(error => {
                            console.error(error);
                            alert(error.message || error);
                          });
                        }}
                      >
                        Reservierung stornieren
                      </Button>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          setShowDeletionModal(false);
                        }}
                      >
                        Abbrechen
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </AuthCheck>
  );
}

export default Ticket;
