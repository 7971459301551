import DateAndTime from "./DateAndTime";
import AvailableSlots from "./AvailableSlots";
import CoronaGuide from "./CoronaGuide";
import Directions from "./Directions";
import React from "react";
import { Link } from "react-router-dom";
import ticketIcon from "../../images/icons/ticket.svg";
import Text from "../../components/Text";
import TwitchGlitchWhite from "../../images/TwitchGlitchWhite.svg";
import { Button } from "react-bootstrap";

function Home() {
  const isCupComing = false;
  if (!isCupComing) {
    return (
      <div className={"container"}>
        <div className="row">
          <div className="col-sm-12 mb-5">
            <div
              className="text-center"
              style={{ maxWidth: 460, margin: "0 auto" }}
            >
              <Text className="mb-2" use="hero">
                Vielen Dank für euren Besuch!
              </Text>
              <Text color={"textColorLight"}>
                Es war ein tolles Event und wir freuen uns auf das Turnier im
                nächsten Jahr mit euch. Bleibt auf dem Laufenden mit{" "}
                <a
                  href="https://www.instagram.com/coesfeld.volleyball/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  instagram.com/coesfeld.volleyball/
                </a>.
              </Text>
              <Text className="mt-3" color="textColorLight">
                Turnier verpasst? Schau dir die Live-Stream Wiederholung von
                TROPS4 auf Twitch an:
              </Text>

              <Button
                className="mt-2 mr-2"
                style={{ backgroundColor: "#772ce8", borderColor: "#772ce8" }}
                onClick={() => {
                  window.open(
                    "https://www.twitch.tv/videos/711059826",
                    "_blank"
                  );
                }}
              >
                <img
                  src={TwitchGlitchWhite}
                  alt="Twitch"
                  style={{ width: 20, height: 20 }}
                  className="mr-1"
                />
                Sa. 15.08.
              </Button>
              <Button
                className="mt-2"
                style={{ backgroundColor: "#772ce8", borderColor: "#772ce8" }}
                onClick={() => {
                  window.open(
                    "https://www.twitch.tv/videos/711884426",
                    "_blank"
                  );
                }}
              >
                <img
                  src={TwitchGlitchWhite}
                  alt="Twitch"
                  style={{ width: 20, height: 20 }}
                  className="mr-1"
                />
                So. 16.08.
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12 col-md-6 mb-3 mb-md-4 d-md-flex align-items-stretch">
          <DateAndTime />
        </div>
        <div className="col-sm-12 col-md-6 mb-3 mb-md-4 d-md-flex align-items-stretch flex-column">
          <AvailableSlots />
          <div className="mt-3 text-center">
            <Link to="reservation/intro" className="btn btn-warning btn-block">
              <img
                src={ticketIcon}
                className="mr-2"
                aria-hidden="true"
                alt="Ticket"
              />
              Jetzt kostenlos Plätze reservieren
            </Link>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-6 mb-3 mb-md-4 d-md-flex align-items-stretch">
          <CoronaGuide />
        </div>
        <div className="col-sm-12 col-md-6 mb-3 mb-md-4 d-md-flex align-items-stretch">
          <Directions />
        </div>
      </div>
      <div className="row">
        <div className="col mb-5 text-right">
          <Link to="/admin">Admin</Link>
        </div>
      </div>
    </div>
  );
}

export default Home;
