import React from "react";
import AppHeader from "./components/AppHeader";
import { SuspenseWithPerf } from "reactfire";
import LoadingSpinner from "./components/LoadingSpinner";
import Admin from "./pages/admin/Admin";
import Home from "./pages/home/Home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Reservation from "./pages/reservation/Reservation";
import Reservations from "./pages/admin/Reservations";
import PageTracker from "./PageTracker";

function App() {
  return (
    <SuspenseWithPerf fallback={<LoadingSpinner />} traceId={"app"}>
      <Router>
        <AppHeader />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/admin">
            <Admin />
          </Route>
          <Route path="/reservations">
            <Reservations />
          </Route>
          <Route path="/reservation">
            <Reservation />
          </Route>
        </Switch>
        <PageTracker />
      </Router>
    </SuspenseWithPerf>
  );
}

export default App;
