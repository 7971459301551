import React from "react";
import classNames from "classnames";
import styles from "./Card.module.css";

function Card({className, children}) {
  return (
    <div
      className={classNames(styles.wrapper, className)}
      style={{width: "100%"}}
    >
      {children}
    </div>
  );
}

export default Card;
