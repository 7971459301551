import React from "react";
import logo from "../images/premium-cup-logo.svg";
import { Link } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import ticketWhite from "../images/icons/ticketWhite.svg";
import { AuthCheck } from "reactfire";
import Text from "./Text";

function AppHeader() {
  return (
    <Navbar
      className="pb-3 pt-3 mb-4 mb-md-5"
      style={{
        borderBottom: "1px solid var(--background-light)",
        backgroundColor: "var(--background-dark)"
      }}
      sticky="top"
    >
      <div className="container">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ width: "100%" }}
        >
          <Navbar.Brand>
            <Link to={"/"}>
              <img
                src={logo}
                alt="Sparkassen Beach Premium Cup"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </Link>
          </Navbar.Brand>
          <AuthCheck fallback={null}>
            <Link to="/reservation/ticket">
              <div className="d-flex align-items-center text-right">
                <Text color="linkColor" use="callout" className="mr-2" bold>
                  DEINE RESERVIERUNG
                </Text>
                <img
                  src={ticketWhite}
                  alt="Ticket"
                  style={{ width: 30, height: "auto", display: "inline-block" }}
                />
              </div>
            </Link>
          </AuthCheck>
        </div>
      </div>
    </Navbar>
  );
}

export default AppHeader;
