import React from "react";
import CardContent from "../../components/CardContent";
import CircleIcon from "../../components/CircleIcon";
import Text from "../../components/Text";
import Card from "../../components/Card";
import bangIcon from "../../images/icons/bang.svg";

function CoronaGuide() {
  return (
    <Card>
      <CardContent>
        <div className="mb-3 d-flex align-items-center">
          <div className="mr-2">
            <CircleIcon color="purple" icon={bangIcon} />
          </div>
          <Text use="headline" color="textColorLight">
            Corona Hinweise
          </Text>
        </div>
        <div className="mb-2">
          <Text>
            Die Besucherzahl ist zu deinem Schutz auf maximal 300 Personen
            gleichzeitig auf dem Gelände beschränkt.
          </Text>
        </div>
        <div className="mb-2">
          <Text>
            Du kannst dich frei bewegen und es besteht ausschließlich in den
            geschlossenen Räumen, wie z.B. den Sanitäranlagen, Maskenpflicht.
          </Text>
        </div>
        <div className="mb-2">
          <Text>
            Es gilt die Abstandsregelung von 1,50 Metern zu anderen Personen.
            Cafeteria, Grill und kühle Getränke sorgen wie gewohnt für das
            leibliche Wohl.
          </Text>
        </div>
        <Text use="footnote" color="textColorLight">
          An den getrennten Ein- und Ausgängen wirst du gebeten, deine
          Kontaktdaten zu hinterlassen, um eine mögliche Rückverfolgung zu
          gewährleisten.
        </Text>
      </CardContent>
    </Card>
  );
}

export default CoronaGuide;
