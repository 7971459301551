import React from "react";
import classNames from "classnames";
import styles from "./Button.module.css";

function Button({title, action, disabled, className}) {
  return (
    <button
      onClick={disabled ? undefined : action}
      className={classNames(styles.container, className)}
      disabled={disabled}
    >
      {title}
    </button>
  );
}

export default Button;
