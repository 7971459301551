import _ from "lodash";
import moment from "moment";

function getTicketEndDate(timestamp) {
  return moment(timestamp)
    .add(30, "minutes")
    .toISOString();
}

function hasTicket(ticket) {
  return !(ticket == null || _.isEmpty(ticket) || ticket.time == null);
}

function isTicketValid(ticket) {
  if (!hasTicket(ticket)) {
    return false;
  }
  const now = moment();
  const validUntil = getTicketEndDate(ticket.time);
  return now.diff(validUntil) < 0;
}

const ticketHelpers = {
  getTicketEndDate,
  isTicketValid,
  hasTicket
};

export default ticketHelpers;
