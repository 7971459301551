import React from "react";
import ReactDOM from "react-dom";
import {FirebaseAppProvider} from "reactfire";
import "./css/index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

const firebaseConfig = {
  apiKey: "AIzaSyAA5cHeKi3Tzp2NXw2K7DrbsDWYfhLtoSI",
  authDomain: "premium-cup.firebaseapp.com",
  databaseURL: "https://premium-cup.firebaseio.com",
  projectId: "premium-cup",
  storageBucket: "premium-cup.appspot.com",
  messagingSenderId: "404633466162",
  appId: "1:404633466162:web:32ba20fa738b10fdba132b",
  measurementId: "G-ZT48TRY56S"
};

ReactDOM.render(
  <React.StrictMode>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <App/>
    </FirebaseAppProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
