import React from "react";
import styles from "./CircleIcon.module.css";
import Colors from "../helpers/Colors";
import * as classnames from "classnames";

function CircleIcon({icon, color, pulse}) {
  const customStyle = {
    backgroundColor: Colors[color]
  };
  return (
    <div
      className={classnames([styles.wrapper, pulse && "pulse"])}
      style={customStyle}
    >
      <img src={icon} className={styles.icon} aria-hidden="true" alt="Icon"/>
    </div>
  );
}

export default CircleIcon;
