import React from "react";
import moment from "moment";
import Text from "../../components/Text";
import { Link, Redirect, withRouter } from "react-router-dom";
import { Formik } from "formik";
import { Button, ButtonGroup, Form } from "react-bootstrap";
import {
  useAnalytics,
  useAuth,
  useFirestore,
  useFirestoreDocData
} from "reactfire";
import Card from "../../components/Card";
import CardContent from "../../components/CardContent";
import CircleIcon from "../../components/CircleIcon";
import calendarIcon from "../../images/icons/id.svg";
import * as yup from "yup";
import ticketHelpers from "../../helpers/ticketHelpers";

const schema = yup.object({
  firstName: yup.string().required(),
  familyName: yup.string().required(),
  phoneNumber: yup.string().required(),
  persons: yup.number().required()
});

function Checkout(props) {
  const auth = useAuth();
  const analytics = useAnalytics();
  const user = auth.currentUser;
  const pcRef = useFirestore()
    .collection("events")
    .doc("premium-cup");
  const ticketRef = pcRef
    .collection("reservations")
    .doc(user != null ? user.uid : "empty");

  const pc = useFirestoreDocData(pcRef);
  const ticket = useFirestoreDocData(ticketRef);

  const validTicket = ticketHelpers.isTicketValid(ticket);
  if (validTicket) {
    return (
      <Redirect
        to={{
          pathname: "/reservation/ticket"
        }}
      />
    );
  }

  const slotsReservationAvailable =
    pc.slotsReservationVolume - pc.slotsReservationTaken;

  const reservationPossible =
    pc.capacityDisplayMode !== "RED" &&
    pc.capacityDisplayMode !== "OFF" &&
    slotsReservationAvailable > 0;

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12 mb-5">
          {reservationPossible ? (
            <div style={{maxWidth: 400, margin: "0 auto"}}>
              <div className="text-center mb-4">
                <Text use="hero">Reserviere dir deinen Platz</Text>
              </div>
              <Card>
                <CardContent>
                  <div className="mb-3 d-flex align-items-center">
                    <div className="mr-2">
                      <CircleIcon color="berry" icon={calendarIcon}/>
                    </div>
                    <Text use="headline" color="textColorLight">
                      Deine Angaben
                    </Text>
                  </div>
                  <Formik
                    initialValues={{
                      firstName: "",
                      familyName: "",
                      phoneNumber: "",
                      persons: 1
                    }}
                    validationSchema={schema}
                    onSubmit={async (values, {setSubmitting, isValid}) => {
                      setSubmitting(true);

                      if (
                        values.persons > slotsReservationAvailable ||
                        !reservationPossible
                      ) {
                        alert(
                          "Leider sind nicht mehr ausreichend viele Plätze für Reservierungen verfügbar. Bitte versuche es später noch einmal."
                        );
                        return;
                      }
                      let user = auth.currentUser;
                      if (!user) {
                        await auth
                          .signInAnonymously()
                          .then(async ({user}) => {
                            await analytics.setUserId(user.uid);
                          })
                          .catch(function (error) {
                            console.error(error);
                            alert(error.message || error);
                          });
                        user = auth.currentUser;
                      }
                      await pcRef
                        .update({
                          slotsReservationTaken: (pc.slotsReservationTaken +=
                            values.persons)
                        })
                        .catch(error => {
                          console.error(error);
                        });
                      const reservation = {
                        ...values,
                        time: moment().toISOString()
                      };
                      await pcRef
                        .collection("reservations")
                        .doc(user.uid)
                        .set(reservation)
                        .catch(error => {
                          alert(
                            "Deine Reservierung konnte nicht erstellt werden"
                          );
                          console.error(error);
                        });
                      setSubmitting(false);
                      props.history.push("/reservation/ticket");
                    }}
                    validateOnChange={false}
                    validateOnBlur={true}
                  >
                    {({
                        values,
                        errors,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        touched
                        /* and other goodies */
                      }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <Form.Group controlId="firstName">
                          <Form.Label>Vorname</Form.Label>
                          <Form.Control
                            type="text"
                            name="firstName"
                            value={values.firstName}
                            onChange={handleChange}
                            isInvalid={!!errors.firstName}
                            autoComplete="given-name"
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.firstName}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="familyName">
                          <Form.Label>Nachname</Form.Label>
                          <Form.Control
                            type="text"
                            name="familyName"
                            value={values.familyName}
                            onChange={handleChange}
                            isInvalid={!!errors.familyName}
                            autoComplete="family-name"
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.familyName}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="phoneNumber">
                          <Form.Label>Mobilfunknummer</Form.Label>
                          <Form.Control
                            type="tel"
                            name="phoneNumber"
                            value={values.phoneNumber}
                            onChange={handleChange}
                            isInvalid={!!errors.phoneNumber}
                            autoComplete="tel"
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.phoneNumber}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="persons">
                          <Form.Label>Anzahl der Personen</Form.Label>
                          <div>
                            <ButtonGroup size="lg">
                              <Button
                                onClick={() => {
                                  setFieldValue("persons", 1);
                                }}
                                active={values.persons === 1}
                              >
                                1
                              </Button>
                              <Button
                                onClick={() => {
                                  setFieldValue("persons", 2);
                                }}
                                active={values.persons === 2}
                              >
                                2
                              </Button>
                              <Button
                                onClick={() => {
                                  setFieldValue("persons", 3);
                                }}
                                active={values.persons === 3}
                              >
                                3
                              </Button>
                              <Button
                                onClick={() => {
                                  setFieldValue("persons", 4);
                                }}
                                active={values.persons === 4}
                              >
                                4
                              </Button>
                            </ButtonGroup>
                          </div>
                          <Form.Control.Feedback type="invalid">
                            {errors.persons}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                          <Text use="callout" color="textColorLight">
                            Mit dem Klick auf "Plätze reservieren" akzeptierst
                            du die{" "}
                            <Link to="/reservation/tos">
                              Reservierungsbedingungen
                            </Link>.
                          </Text>
                        </Form.Group>
                        <Button
                          onClick={isSubmitting ? null : handleSubmit}
                          block
                          size="lg"
                          variant="warning"
                          disabled={isSubmitting}
                        >
                          {isSubmitting
                            ? "Wird reserviert…"
                            : "Plätze reservieren"}
                        </Button>
                        <div className="mt-3 text-center">
                          <Text use="callout" color="textColorLight">
                            Die Reservierung ist kostenlos und immer nur für 30
                            Minuten gültig.
                          </Text>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </CardContent>
              </Card>
            </div>
          ) : (
            <div style={{maxWidth: 400, margin: "0 auto"}}>
              <div className="text-center mb-4">
                <Text className="mb-3" use="hero">
                  Momentan können leider keine Plätze reserviert werden
                </Text>
                {slotsReservationAvailable === 0 ? (
                  <Text color="textColorLight" use="title3">
                    Es wurden bereits sehr viele Tickets reserviert. Sobald
                    Besucher mit Reservierungen angekommen sind oder deren
                    Reservierung verfällt, werden erneut Plätze zur Reservierung
                    freigegeben.
                  </Text>
                ) : (
                  <Text color="textColorLight" use="title3">
                    Es ist sehr viel auf dem Gelände los und wir können dir
                    keinen Einlass garantieren. Bitte versuche es in einigen
                    Minuten nochmal.
                  </Text>
                )}
                <div className="d-flex align-items-center justify-content-center mt-3">
                  <div
                    className="pulse mr-2"
                    style={{
                      height: 14,
                      width: 14,
                      borderRadius: 14,
                      backgroundColor: "var(--colors--orange)"
                    }}
                  />
                  <Text use="subhead" color="textColorLight">
                    Diese Seite aktualisiert sich automatisch.
                  </Text>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default withRouter(Checkout);
