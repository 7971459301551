import React from "react";
import { AuthCheck, SuspenseWithPerf, useAnalytics, useAuth } from "reactfire";
import { Formik } from "formik";
import Button from "./Button";
import InputField from "./InputField";

function LoginForm() {
  const auth = useAuth();
  const analytics = useAnalytics();
  return (
    <SuspenseWithPerf fallback={"asd"} traceId={"user-login"}>
      <AuthCheck
        fallback={
          <Formik
            initialValues={{ email: "admin@admin.com", password: "" }}
            validate={values => {
              const errors = {};
              if (!values.email) {
                errors.email = "Benötigt";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Bitte gib eine gültige E-Mail-Adresse ein";
              }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              await auth
                .signInWithEmailAndPassword(values.email, values.password)
                .then(async ({ user }) => {
                  await analytics.setUserId(user.uid);
                })
                .catch(function(error) {
                  console.error(error);
                  alert(error.message || error);
                });
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
              /* and other goodies */
            }) => (
              <form
                onSubmit={handleSubmit}
                className="d-flex align-items-center justify-content-center"
              >
                <InputField
                  type="email"
                  autoComplete="username"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  placeholder="E-Mail-Adresse"
                  required
                  hidden
                  error={errors.email && touched.email && errors.email}
                />
                <InputField
                  type="password"
                  name="password"
                  autoComplete="current-password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  placeholder="Passwort"
                  required
                  error={errors.password && touched.password && errors.password}
                />
                <Button
                  title="Anmelden"
                  action={handleSubmit}
                  disabled={isSubmitting}
                  className="ml-2"
                />
              </form>
            )}
          </Formik>
        }
      >
        <div className="text-center">
          <Button
            title="Ausloggen"
            action={() => {
              auth.signOut().catch(function(error) {
                console.error(error);
              });
            }}
          />
        </div>
      </AuthCheck>
    </SuspenseWithPerf>
  );
}

export default LoginForm;
